// extracted by mini-css-extract-plugin
export var about = "_about-module--about---4x7I";
export var about__wrap = "_about-module--about__wrap--p9qM5";
export var about__introduction = "_about-module--about__introduction--L-qDl";
export var about__contents = "_about-module--about__contents--NvSmm";
export var about__text = "_about-module--about__text--WxTu7";
export var about__circle_wrap = "_about-module--about__circle_wrap--uBYH4";
export var about__circle1 = "_about-module--about__circle1--xPv2v";
export var about__circle2 = "_about-module--about__circle2--HNUOl";
export var about__circle3 = "_about-module--about__circle3--b-XKO";
export var about__small = "_about-module--about__small--uLGkk";
export var about__big = "_about-module--about__big--zX5Eh";
export var about__img = "_about-module--about__img--JCtm0";