import * as React from 'react';
import * as Styles from '../styles/pages/_about.module.scss';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import * as CommonStyles from '../styles/global/_common.module.scss';
import Accent from '/static/accent.svg';
import Skills from '/static/skills.svg';
import Inspiration from '/static/inspiration.svg';
import Performance from '/static/performance.svg';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const AboutPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  return (
    <Layout location={location}>
      <Seo title={t('about.seo.title')} description={t('about.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.firstLIMITS'),
          },
        ]}
        currentPage={t('menu.aboutLIMITS')}
      />
      <BackGround>
        <section className={Styles.about}>
          <div className={Styles.about__wrap}>
            <div className={CommonStyles.container}>
              <h1>{t('menu.aboutLIMITS')}</h1>
              <div className={Styles.about__introduction}>
                <h2>{t('about.vision.title')}</h2>
                <p>
                  {t('about.vision.text')}
                </p>
              </div>
              <div className={Styles.about__contents}>
                <h3>
                  <img src={Accent} className="accent" alt="accent" />
                  {t('about.whatIs.title')}
                </h3>
                <StaticImage src="../images/about_2.jpg" alt="" />
                <div className={Styles.about__text}>
                  <p>{t('about.whatIs.text1')}</p>
                  <p>{t('about.whatIs.text2')}</p>
                  <p>{t('about.whatIs.text3')}</p>
                  <p>{t('about.whatIs.text4')}</p>
                </div>
              </div>
              <div className={Styles.about__contents}>
                <h3>
                  <img src={Accent} className="accent" alt="accent" />
                  {t('about.whatIsLIMITSBattle.title')}
                </h3>
                <StaticImage src="../images/about_3.jpg" alt="" />
                <div className={Styles.about__text}>
                  <p>{t('about.whatIsLIMITSBattle.text1')}</p>
                  <p>{t('about.whatIsLIMITSBattle.text2')}</p>
                  <p>{t('about.whatIsLIMITSBattle.text3')}</p>
                  <p>{t('about.whatIsLIMITSBattle.text4')}</p>
                </div>
              </div>
              <div className={Styles.about__circle_wrap}>
                <div className={Styles.about__circle1}>
                  <p className={Styles.about__small}>
                    {t('about.other.text1')}
                    <br />
                    {t('about.other.text2')}
                  </p>
                  <p className={Styles.about__big}>{t('about.other.technique')}</p>
                  <img src={Skills} className="skills" alt="skills" />
                </div>
                <div className={Styles.about__circle2}>
                  <p className={Styles.about__small}>
                    {t('about.other.text3')}
                    <br />
                    {t('about.other.text4')}
                  </p>
                  <p className={Styles.about__big}>{t('about.other.creative')}</p>
                  <img src={Inspiration} className="inspiration" alt="inspiration" />
                </div>
                <div className={Styles.about__circle3}>
                  <p className={Styles.about__small}>
                    {t('about.other.text5')}
                    <br />
                    {t('about.other.text6')}
                  </p>
                  <p className={Styles.about__big}>{t('about.other.directing')}</p>
                  <img src={Performance} className="performance" alt="performance" />
                </div>
              </div>
              <div className={Styles.about__contents}>
                <h3>
                  <img src={Accent} className="accent" alt="accent" />
                  {t('about.enjoy.text1')}
                </h3>
                <p>{t('about.enjoy.text1')}</p>
                <p>{t('about.enjoy.text2')}</p>
                <p>{t('about.enjoy.text3')}</p>
                <p>{t('about.enjoy.text4')}</p>
                <div className={Styles.about__img}>
                  <StaticImage src="../images/u_a.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
}

export default AboutPage;
